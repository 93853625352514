<template>
    <v-container
      v-if="status_ladeDaten"
    >
      <v-progress-linear
        indeterminate
      ></v-progress-linear>
    </v-container>
    <v-container
      v-else
    >
      <v-tabs
        fixed-tabs
        v-model="tabs_positionEigenschaften"
        class="d-md-none mb-6"
      >
        <v-tab>Position</v-tab>
        <v-tab>Eigenschaften</v-tab>
      </v-tabs>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-if="tabs_positionEigenschaften === 0 || $vuetify.breakpoint.mdAndUp"
        >
          <!-- POSITION -->
          <p class="text-title text--secondary d-none d-md-block mt-2">
            Position
          </p>
          <v-container class="pa-2">
            <v-row align="center">
              <v-col align="center" cols="2">
                <v-btn
                  v-if="!idHydrant"
                  small
                  @click="
                    $router.push({
                      path: `/310?lat=${hydrant.coords_lat}&lon=${hydrant.coords_lon}&setLocation=1`
                    });
                  "
                >
                    <v-icon small>mdi-crosshairs-gps</v-icon>
                </v-btn>
                <v-btn
                  v-if="idHydrant"
                  small
                  @click="$router.push({ path: `/310?h=${idHydrant}` })"
                >
                    <v-icon small>mdi-map-search</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  type="number"
                  label="Breitengrad"
                  prepend-icon=""
                  v-model="hydrant.coords_lat"
                  :rules="[
                      $store.state.main.inputValidationRules.koordinaten,
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                type="number"
                label="Längengrad"
                prepend-icon=""
                v-model="hydrant.coords_lon"
                :rules="[
                    $store.state.main.inputValidationRules.koordinaten,
                ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                type="text"
                label="Straße"
                prepend-icon=""
                v-model="hydrant.adresse.strasse"
                :rules="[
                    $store.state.main.inputValidationRules.name,
                ]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                type="text"
                label="Hausnummer"
                prepend-icon=""
                v-model="hydrant.adresse.hausnummer"
                :rules="[
                    $store.state.main.inputValidationRules.name,
                ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                type="number"
                label="PLZ"
                prepend-icon=""
                v-model="hydrant.adresse.plz"
                :rules="[
                    $store.state.main.inputValidationRules.nummer,
                ]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                type="text"
                label="Ort"
                prepend-icon=""
                v-model="hydrant.adresse.ort"
                :rules="[
                    $store.state.main.inputValidationRules.name,
                ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col align="center">
                <v-btn
                  small
                  @click="trySetLocationFromAdress();"
                >
                    <v-icon small left>mdi-map-marker-question</v-icon>
                    Koordinaten aus Adresse ermitteln
                </v-btn>
              </v-col>
            </v-row> -->
          </v-container>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-if="tabs_positionEigenschaften === 1 || $vuetify.breakpoint.mdAndUp"
        >
          <!-- EIGENSCHAFTEN -->
          <p class="text-title text--secondary d-none d-md-block mt-2">
            Eigenschaften
          </p>
          <v-container class="pa-2">
            <v-row>
              <v-col>
                <v-text-field
                type="number"
                label="Hydrantennummer"
                prepend-icon="mdi-pound-box"
                v-model="hydrant.nummer"
                :rules="[
                    $store.state.main.inputValidationRules.nummer,
                ]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                type="number"
                label="Leitungsdurchmesser [mm]"
                prepend-icon="mdi-diameter"
                v-model="hydrant.durchmesser"
                :rules="[
                    $store.state.main.inputValidationRules.nummer,
                ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-if="data"
                  v-model="hydrant.status"
                  :items="data.statuswerte"
                  label="Status"
                  item-text="name"
                  item-value="id"
                  prepend-icon="mdi-list-status"
                  >
                </v-select>
                <v-select
                  v-model="hydrant.mangel"
                  :items="data.maengel.map((o) => o.schluessel)"
                  attach chips multiple hide-selected
                  label="Mängel"
                  prepend-icon="mdi-contrast-box"
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      label
                      small
                      >
                        <span class="pr-2">
                          {{
                            data.maengel.filter((o) => o.schluessel === item)[0].name
                          }}
                        </span>
                        <v-icon
                          small
                          @click.native.stop
                          @click="parent.selectItem(item)"
                        >
                          $delete
                        </v-icon>
                      </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    {{
                      data.maengel.filter((o) => o.schluessel === item)[0].name
                    }}
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Keine weiteren Mängel.
                      </v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <v-text-field
                type="text"
                label="Lage des Hydranten"
                prepend-icon="mdi-fire-hydrant"
                v-model="hydrant.lage"
                :rules="[
                    $store.state.main.inputValidationRules.name,
                ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                type="text"
                label="Lage des Hinweisschilds"
                prepend-icon="mdi-sign-direction"
                v-model="hydrant.lageSchild"
                :rules="[
                    $store.state.main.inputValidationRules.name,
                ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <v-textarea
                  label="Info"
                  v-model="hydrant.info"
                  hint="Sonstige Notizen zum Hydranten"
                  prepend-icon="mdi-information"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <v-dialog
                  v-if="hydrant.id !== 0"
                  v-model="dialog_delHydrant"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <v-icon left>mdi-delete</v-icon>
                        Hydrant Löschen
                      </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Sicher?
                    </v-card-title>

                    <v-card-text class="pt-4">
                      Es werden sämtliche mit diesem Hydranten zusammenhängende Daten
                      und Informationen unwiederruflich gelöscht.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn
                        color="primary"
                        text
                        @click="dialog_delHydrant = false;"
                      >
                        Nichts tun
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="tryDelHydrant"
                      >
                        Ja, löschen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn
            :loading="isSaving"
            @click="trySpeichern();"
          >Hydrant speichern</v-btn>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import $ from 'jquery';

export default {
  components: {
  },
  props: {
    value: null,
    idHydrant: null,
    location: null,
    tab: null,
  },
  data: () => ({
    isSaving: false,
    status_ladeDaten: true,
    tabs_positionEigenschaften: 0,
    dialog_delHydrant: null,
    hydrant: {
      isChanged: false,
      id: 0,
      coords_lat: '',
      coords_lon: '',
      status: '2',
      mangel: [],
      adresse: {
        strasse: '',
        hausnummer: '',
        plz: '',
        ort: '',
      },
      hausnummer: '',
      durchmesser: '100',
      lageSchild: '',
      nummer: '',
      lage: '',
      info: '',
    },
    data: null,
  }),
  computed: {
  },
  watch: {
    value: {
      handler(neu) {
        if (neu.hydranten.length > 0 && !this.hydrant.isChanged) {
          this.getHydranten();
        }
      },
      deep: true,
    },
    hydrant: {
      handler(neu) {
        if (neu.id !== 0) {
          this.hydrant.isChanged = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    getHydranten() {
      this.data = this.value;
      if (this.idHydrant) {
        const hyd = this.data.hydranten.filter( // eslint-disable-line prefer-destructuring
          (o) => o.id.toString() === this.idHydrant.toString(),
        )[0];
        this.hydrant.coords_lat = hyd.coords_lat;
        this.hydrant.coords_lon = hyd.coords_lon;
        this.hydrant.adresse.hausnummer = hyd.hausnummer;
        this.hydrant.adresse.strasse = this.data.strassen.filter(
          (o) => o.id === hyd.idStrasse,
        )[0].strasse;
        this.hydrant.adresse.plz = this.data.strassen.filter(
          (o) => o.id === hyd.idStrasse,
        )[0].plz;
        this.hydrant.adresse.ort = this.data.strassen.filter(
          (o) => o.id === hyd.idStrasse,
        )[0].ort;
        this.hydrant.status = hyd.status;
        this.hydrant.mangel = hyd.mangel;
        this.hydrant.id = hyd.id;
        this.hydrant.lageSchild = hyd.lageSchild;
        this.hydrant.nummer = hyd.nummer;
        this.hydrant.lage = hyd.lage;
        this.hydrant.durchmesser = hyd.durchmesser;
        this.hydrant.info = hyd.info;
      }
      this.status_ladeDaten = false;
      // Per Query übergebene Koordinaten eintragen:
      if (this.location.lat) {
        this.hydrant.coords_lat = this.location.lat;
        this.hydrant.coords_lon = this.location.lon;
        this.trySetAdressFromLocation(this.hydrant.coords_lat, this.hydrant.coords_lon);
      }
    },
    async trySetLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((pos) => {
          this.hydrant.coords_lat = pos.coords.latitude;
          this.hydrant.coords_lon = pos.coords.longitude;
          this.trySetAdressFromLocation(pos.coords.latitude, pos.coords.longitude);
        }, (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            this.$store.commit('main/alert', {
              typ: 'error',
              text: 'Standortermittlung ist nicht aktiviert.',
            }, { root: true });
          }
        });
      } else {
        this.$store.commit('main/alert', {
          typ: 'error',
          text: 'Standortermittlung wird nicht unterstützt.',
        }, { root: true });
      }
    },
    async trySetAdressFromLocation(lat, lon) {
      $.ajax({
        // url: `https://nominatim.openstreetmap.org/search?format=json&q=<ADRESSE>`,
        url: `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`,
        type: 'post',
        success: (response) => {
          if (response.address.house_number && response.address.country_code === 'de') {
            this.hydrant.adresse.hausnummer = response.address.house_number;
            this.hydrant.adresse.strasse = response.address.road;
            this.hydrant.adresse.plz = response.address.postcode;
            if (response.address.city) {
              this.hydrant.adresse.ort = response.address.city;
            } else if (response.address.town) {
              this.hydrant.adresse.ort = response.address.town;
            }
          } else {
            this.$store.commit('main/alert', {
              typ: 'error',
              text: 'Es konnte keine Adresse aus den Koordinaten ermittelt werden.',
            }, { root: true });
          }
        },
        timeout: 10000,
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Es konnte keine Adresse ermittelt werden. Bitte Netzwerkverbindung prüfen.',
          }, { root: true });
        },
      });
    },
    async trySetLocationFromAdress() {
      const q = `${this.hydrant.adresse.strasse} ${this.hydrant.adresse.hausnummer}, ${this.hydrant.adresse.plz} ${this.hydrant.adresse.ort}`;
      $.ajax({
        url: `https://nominatim.openstreetmap.org/search?format=json&q=${q}`,
        // url: `https://nominatim.openstreetmap.org/reverse?lat=${pos.coords.latitude}&lon=${pos.coords.longitude}&format=json`,
        type: 'post',
        success: (response) => {
          if (response[0]) {
            this.hydrant.coords_lat = response[0].lat;
            this.hydrant.coords_lon = response[0].lon;
          } else {
            this.$store.commit('main/alert', {
              typ: 'error',
              text: 'Es konnten keine Koordinaten aus der Adresse ermittelt werden.',
            }, { root: true });
          }
        },
        timeout: 10000,
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Es konnte keine Adresse ermittelt werden. Bitte Netzwerkverbindung prüfen.',
          }, { root: true });
        },
      });
    },
    async trySpeichern() {
      this.$store.commit('main/switchIsSaving', true);
      this.isSaving = true;
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/300/310/karte.php?func=saveHydrant`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          hydrant: JSON.stringify(this.hydrant),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.$store.commit('main/switchIsSaving', false);
            this.isSaving = false;
            this.$router.push({ path: `/310?lat=${this.hydrant.coords_lat}&lon=${this.hydrant.coords_lon}` });
            this.$store.commit('main/alert', {
              typ: 'success',
              text: 'Hydrant erfolgreich gespeichert!',
            }, { root: true });
            this.$emit('reload');
          }
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          this.$store.commit('main/switchIsSaving', false);
          this.isSaving = false;
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Hydrant konnte nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
      });
    },
    async tryDelHydrant() {
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/300/310/karte.php?func=delHydrant`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          idHydrant: this.hydrant.id,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.$router.push({ path: `/310?lat=${this.hydrant.coords_lat}&lon=${this.hydrant.coords_lon}` });
            this.$store.commit('main/alert', {
              typ: 'success',
              text: 'Hydrant erfolgreich gelöscht!',
            }, { root: true });
          }
          this.$store.commit('main/switchIsSaving', false);
          this.$emit('reload');
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Hydrant konnte nicht gelöscht werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
          this.$store.commit('main/switchIsSaving', false);
        },
      });
    },
  },
  created() {
    this.$emit('reload');
    if (this.tab) {
      this.tabs_positionEigenschaften = parseInt(this.tab, 10);
    }
  },
};
</script>

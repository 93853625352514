var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.status_ladeDaten)?_c('v-container',[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):_c('v-container',[_c('v-tabs',{staticClass:"d-md-none mb-6",attrs:{"fixed-tabs":""},model:{value:(_vm.tabs_positionEigenschaften),callback:function ($$v) {_vm.tabs_positionEigenschaften=$$v},expression:"tabs_positionEigenschaften"}},[_c('v-tab',[_vm._v("Position")]),_c('v-tab',[_vm._v("Eigenschaften")])],1),_c('v-row',[(_vm.tabs_positionEigenschaften === 0 || _vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"text-title text--secondary d-none d-md-block mt-2"},[_vm._v(" Position ")]),_c('v-container',{staticClass:"pa-2"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"align":"center","cols":"2"}},[(!_vm.idHydrant)?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.$router.push({
                  path: ("/310?lat=" + (_vm.hydrant.coords_lat) + "&lon=" + (_vm.hydrant.coords_lon) + "&setLocation=1")
                });}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-crosshairs-gps")])],1):_vm._e(),(_vm.idHydrant)?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.$router.push({ path: ("/310?h=" + _vm.idHydrant) })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-map-search")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"type":"number","label":"Breitengrad","prepend-icon":"","rules":[
                  _vm.$store.state.main.inputValidationRules.koordinaten ]},model:{value:(_vm.hydrant.coords_lat),callback:function ($$v) {_vm.$set(_vm.hydrant, "coords_lat", $$v)},expression:"hydrant.coords_lat"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"type":"number","label":"Längengrad","prepend-icon":"","rules":[
                _vm.$store.state.main.inputValidationRules.koordinaten ]},model:{value:(_vm.hydrant.coords_lon),callback:function ($$v) {_vm.$set(_vm.hydrant, "coords_lon", $$v)},expression:"hydrant.coords_lon"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"text","label":"Straße","prepend-icon":"","rules":[
                _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.hydrant.adresse.strasse),callback:function ($$v) {_vm.$set(_vm.hydrant.adresse, "strasse", $$v)},expression:"hydrant.adresse.strasse"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"text","label":"Hausnummer","prepend-icon":"","rules":[
                _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.hydrant.adresse.hausnummer),callback:function ($$v) {_vm.$set(_vm.hydrant.adresse, "hausnummer", $$v)},expression:"hydrant.adresse.hausnummer"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"PLZ","prepend-icon":"","rules":[
                _vm.$store.state.main.inputValidationRules.nummer ]},model:{value:(_vm.hydrant.adresse.plz),callback:function ($$v) {_vm.$set(_vm.hydrant.adresse, "plz", $$v)},expression:"hydrant.adresse.plz"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"text","label":"Ort","prepend-icon":"","rules":[
                _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.hydrant.adresse.ort),callback:function ($$v) {_vm.$set(_vm.hydrant.adresse, "ort", $$v)},expression:"hydrant.adresse.ort"}})],1)],1)],1)],1):_vm._e(),(_vm.tabs_positionEigenschaften === 1 || _vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"text-title text--secondary d-none d-md-block mt-2"},[_vm._v(" Eigenschaften ")]),_c('v-container',{staticClass:"pa-2"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Hydrantennummer","prepend-icon":"mdi-pound-box","rules":[
                _vm.$store.state.main.inputValidationRules.nummer ]},model:{value:(_vm.hydrant.nummer),callback:function ($$v) {_vm.$set(_vm.hydrant, "nummer", $$v)},expression:"hydrant.nummer"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Leitungsdurchmesser [mm]","prepend-icon":"mdi-diameter","rules":[
                _vm.$store.state.main.inputValidationRules.nummer ]},model:{value:(_vm.hydrant.durchmesser),callback:function ($$v) {_vm.$set(_vm.hydrant, "durchmesser", $$v)},expression:"hydrant.durchmesser"}})],1)],1),_c('v-row',[_c('v-col',[(_vm.data)?_c('v-select',{attrs:{"items":_vm.data.statuswerte,"label":"Status","item-text":"name","item-value":"id","prepend-icon":"mdi-list-status"},model:{value:(_vm.hydrant.status),callback:function ($$v) {_vm.$set(_vm.hydrant, "status", $$v)},expression:"hydrant.status"}}):_vm._e(),_c('v-select',{attrs:{"items":_vm.data.maengel.map(function (o) { return o.schluessel; }),"attach":"","chips":"","multiple":"","hide-selected":"","label":"Mängel","prepend-icon":"mdi-contrast-box"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var attrs = ref.attrs;
            var item = ref.item;
            var parent = ref.parent;
            var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(_vm.data.maengel.filter(function (o) { return o.schluessel === item; })[0].name)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" $delete ")])],1)]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.data.maengel.filter(function (o) { return o.schluessel === item; })[0].name)+" ")]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Keine weiteren Mängel. ")])],1)],1)]},proxy:true}],null,false,771963102),model:{value:(_vm.hydrant.mangel),callback:function ($$v) {_vm.$set(_vm.hydrant, "mangel", $$v)},expression:"hydrant.mangel"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-text-field',{attrs:{"type":"text","label":"Lage des Hydranten","prepend-icon":"mdi-fire-hydrant","rules":[
                _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.hydrant.lage),callback:function ($$v) {_vm.$set(_vm.hydrant, "lage", $$v)},expression:"hydrant.lage"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"type":"text","label":"Lage des Hinweisschilds","prepend-icon":"mdi-sign-direction","rules":[
                _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.hydrant.lageSchild),callback:function ($$v) {_vm.$set(_vm.hydrant, "lageSchild", $$v)},expression:"hydrant.lageSchild"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-textarea',{attrs:{"label":"Info","hint":"Sonstige Notizen zum Hydranten","prepend-icon":"mdi-information"},model:{value:(_vm.hydrant.info),callback:function ($$v) {_vm.$set(_vm.hydrant, "info", $$v)},expression:"hydrant.info"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[(_vm.hydrant.id !== 0)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Hydrant Löschen ")],1)]}}],null,false,3286631133),model:{value:(_vm.dialog_delHydrant),callback:function ($$v) {_vm.dialog_delHydrant=$$v},expression:"dialog_delHydrant"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Sicher? ")]),_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" Es werden sämtliche mit diesem Hydranten zusammenhängende Daten und Informationen unwiederruflich gelöscht. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog_delHydrant = false;}}},[_vm._v(" Nichts tun ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.tryDelHydrant}},[_vm._v(" Ja, löschen ")])],1)],1)],1):_vm._e()],1)],1)],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{attrs:{"loading":_vm.isSaving},on:{"click":function($event){return _vm.trySpeichern();}}},[_vm._v("Hydrant speichern")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }